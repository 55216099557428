import React from 'react';
import smart_task_image from '../pages/images/smart-task-image.svg';
import loop_documentation from '../pages/images/loop-documentation.svg';
import customized_automation_image from '../pages/images/customized-automation-image.svg';

const HowCaddiWorksSection = () => {
    return (
        <section className="section_client">
            <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-large padding-top">
                        <div className="margin-bottom margin-xlarge">
                            <div className="max-width-xlarge ">   
                                <div className="margin-bottom margin-small">
                                <div
                                    data-w-id="74ad06de-982a-32fa-f2b9-49b478412f73"
                                    style={{ opacity: 100 }}
                                    className="glowing-wrapper glowing-wrapper-active tagline"
                                >
                                    <div className="glowing-wrapper-animations">
                                    <div className="glowing-wrapper-glow" />
                                    <div className="glowing-wrapper-mask-wrapper">
                                        <div className="glowing-wrapper-mask" />
                                    </div>
                                    </div>
                                    <div className="glowing-wrapper-borders-masker">
                                    <div className="glowing-wrapper-borders" />
                                    </div>
                                        <div className="glowing-wrapper-button">How Caddi Works</div>
                                    </div>
                                </div>
                                <h2 className="section-title-left">
                                    Automation made <span className="text-color-primary">simple</span>
                                </h2>
                            </div>
                        </div>
                        <div className="how-caddi-works_grid">
                            <div className="how-caddi-works_card">
                                <div className="how-caddi-works_card-body">
                                    <h3 className="integration_card-title">
                                        Smart Task Detection
                                    </h3>
                                    <p>
                                        Caddi identifies and understands the tasks you perform, pinpointing key actions for automation.
                                    </p>
                                </div>
                                <div className="how-caddi-works_card-image-container">
                                    <img
                                        src={smart_task_image}
                                        loading="lazy"
                                        alt="Smart Task Detection"
                                        className="how-caddi-works_card-thumbnail"
                                    />
                                </div>
                            </div>
                            <div className="how-caddi-works_card">
                                <div className="how-caddi-works_card-body">
                                    <h3 className="integration_card-title">
                                        Detailed Loop Documentation
                                    </h3>
                                    <p>
                                        Caddi meticulously documents every step of the process to ensure accuracy and completeness.
                                    </p>
                                </div>
                                <div className="how-caddi-works_card-image-container">
                                    <img
                                        src={loop_documentation}
                                        loading="lazy"
                                        alt="Detailed Loop Documentation"
                                        className="how-caddi-works_card-thumbnail"
                                    />
                                </div>
                            </div>
                            <div className="how-caddi-works_card">
                                <div className="how-caddi-works_card-body">
                                    <h3 className="integration_card-title">
                                        Customized Automations
                                    </h3>
                                    <p>
                                        Caddi translates a human process into an automation, ready for you to test.
                                    </p>
                                </div>
                                <div className="how-caddi-works_card-image-container">
                                    <img
                                        src={customized_automation_image}
                                        loading="lazy"
                                        alt="Customized Automations"
                                        className="how-caddi-works_card-thumbnail"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowCaddiWorksSection;