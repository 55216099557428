import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import image9183_1 from '../pages/images/Frame-9183_new.svg';
import image9183 from '../pages/images/Frame-9183_new.svg';

const HeroSection = () => {
    
    const handleLearnMoreClick = (e) => {
        e.preventDefault();
        const element = document.getElementById('section_start');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className="section_hero">
            <div className="padding-global">
                <div className="container-large">
                    <div className="hero_component">
                        <div className="hero_content">
                            <div className="glowing-wrapper glowing-wrapper-active tagline">
                                <div className="glowing-wrapper-animations">
                                    <div className="glowing-wrapper-glow" />
                                    <div className="glowing-wrapper-mask-wrapper">
                                        <div className="glowing-wrapper-mask" />
                                    </div>
                                </div>
                                <div className="glowing-wrapper-borders-masker">
                                    <div className="glowing-wrapper-borders" />
                                </div>
                                <div className="glowing-wrapper-button">
                                    AI-powered Automation
                                </div>
                            </div>
                            <h1 style={{
                                color: '#26443C',
                                fontSize: '3.5rem',
                                lineHeight: '1.2',
                                marginBottom: '0.5rem'
                            }}>
                                Your Automation Caddi
                            </h1>
                            <p className="hero_para">
                                Automate Repetitive Tasks Effortlessly
                            </p>
                            <div className="button-group hero_button-group">
                                <Link to="/schedule-demo" className="button w-button">
                                    Request demo
                                </Link>
                                <a href="#section_start" className="button is-link is-icon w-inline-block" onClick={handleLearnMoreClick}>
                                    <div>Learn More</div>
                                    <div className="button-arrow w-embed">
                                        <svg width={14} height={9} viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1.41406L7 7.41406L13 1.41406" stroke="#F5C155" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="hero_media-wrap">
                            <img 
                                src={image9183_1} 
                                loading="lazy" 
                                alt="" 
                                className="hero_media desktop" 
                                style={{ maxWidth: '60%', height: 'auto' }}
                            />
                            <img 
                                src={image9183} 
                                loading="lazy" 
                                alt="" 
                                className="hero_media mobile" 
                                style={{ maxWidth: '70%', height: 'auto' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;