import React from 'react';
import data_syncing_image from '../pages/images/data-syncing-image.svg';
import client_onboarding from '../pages/images/client-onboarding.svg';
import approval_image from '../pages/images/approval-image.svg';


const UseCasesSection = React.forwardRef((props, ref) => {
    return (
        <section className="section_use_cases">
            <div className="padding-global">
                <div className="container-large">
                <div className="padding-section-large padding-top">
                    <div className="margin-bottom margin-xlarge">
                        <div className="max-width-xlarge align-center text-align-center">   
                        <div className="margin-bottom margin-small">
                        <div
                            data-w-id="74ad06de-982a-32fa-f2b9-49b478412f73"
                            style={{ opacity: 100 }}
                            className="glowing-wrapper glowing-wrapper-active tagline"
                        >
                            <div className="glowing-wrapper-animations">
                            <div className="glowing-wrapper-glow" />
                            <div className="glowing-wrapper-mask-wrapper">
                                <div className="glowing-wrapper-mask" />
                            </div>
                            </div>
                            <div className="glowing-wrapper-borders-masker">
                            <div className="glowing-wrapper-borders" />
                            </div>
                            <div className="glowing-wrapper-button">Use cases</div>
                        </div>
                        </div>
                        <h2 className="section-title">
                            Caddi simplifies your
                            <span className="text-color-primary">&nbsp;back office</span>
                        </h2>
                    </div>
                    </div>
                    <div className="w-layout-grid client_grid">
                        <div
                            id="w-node-_1094f0d5-5b9d-4b92-1934-2322dfa8d448-acad4190"
                            data-w-id="1094f0d5-5b9d-4b92-1934-2322dfa8d448"
                            style={{ opacity: 100 }}
                            className="client_card"
                        >
                            <div className="client_card-body">
                                <h3 className="integration_card-title">
                                    Data syncing across platforms
                                </h3>
                                <p className='integration_card-subtitle'>
                                    Keep all your tools in sync automatically, eliminating the need for manual data entry and reducing errors.
                                </p>
                            </div>
                            <div className="client_card-image-container">
                                <img
                                src={data_syncing_image}
                                loading="lazy"
                                alt=""
                                className="client_card-thumbnail"
                                />
                            </div>
                        </div>
    
                        <div
                            id="w-node-e6e1f1ac-930b-b177-54d8-224cce19b48a-acad4190"
                            data-w-id="e6e1f1ac-930b-b177-54d8-224cce19b48a"
                            style={{ opacity: 100 }}
                            className="client_card"
                        >
                            <div className="client_card-image-container">
                                <img
                                src={client_onboarding}
                                loading="lazy"
                                alt=""
                                className="client_card-thumbnail"
                                />
                            </div>
                        
                            <div className="client_card-body">
                                <h3 className="integration_card-title">
                                    Client Onboarding
                                </h3>
                                <p className='integration_card-subtitle'>
                                    Simplify the setup of new accounts and automate the entry of client information across your systems.
                                </p>
                            </div>
                        </div>
                        <div
                            id="w-node-_1094f0d5-5b9d-4b92-1934-2322dfa8d448-acad4190"
                            data-w-id="1094f0d5-5b9d-4b92-1934-2322dfa8d448"
                            style={{ opacity: 100 }}
                            className="client_card"
                        >
                            <div className="client_card-body">
                                <h3 className="integration_card-title">
                                    Internal Approval Processes
                                </h3>
                                <p className='integration_card-subtitle'>
                                    Streamline internal processes that require multiple approvals.
                                </p>
                            </div>
                            <div className="client_card-image-container">
                                <img
                                src={approval_image}
                                loading="lazy"
                                alt=""
                                className="client_card-thumbnail"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="section-divider" /> */}
                </div>
                </div>
            </div>
        </section>
    );
});

export default UseCasesSection;