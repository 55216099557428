
// Generated from template similar to Termly

const TermsConditionsContent = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms of Use</title>
    <style>
        body {
            line-height: 1.6;
            margin: 0 auto;
            padding: 0px;
            margin-top: 10px;
        }
        .table-of-contents {
            background-color: #f9f9f9;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
        }
        .table-of-contents ol {
            margin: 0;
            padding-left: 20px;
        }
        .section {
            margin-top: 0px;
        }
        ul {
            padding-left: 20px;
        }
        [data-custom-class='body'], [data-custom-class='body'] * {
            background: transparent !important;
        }
        [data-custom-class='title'], [data-custom-class='title'] * {
            font-size: 26px !important;
            color: #000000 !important;
            font-weight: bold; /* Added bold for emphasis */
            
            margin-bottom: 10px; /* Added margin for spacing */
        }
        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            color: #595959 !important;
            font-size: 14px !important;
            margin-bottom: 20px; /* Added margin for spacing */
        }
        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font-size: 19px !important;
            color: #000000 !important;
            margin-top: 20px; /* Added margin for spacing */
        }
        [data-custom-class='heading_2'], [data-custom-class='heading_2'] * { 
            font-size: 17px !important;
            color: #000000 !important;
            margin-top: 15px; /* Added margin for spacing */
        }
        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
            font-size: 14px !important;
        }
        [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
            font-size: 14px !important;
            word-break: break-word !important;
            text-decoration: underline; /* Underline for links */
        }
        [data-custom-class='link']:hover, [data-custom-class='link'] *:hover {
            color: #1a1aff; /* Darker color on hover */
        }
    </style>
</head>
<body data-custom-class="body">
    <h1 data-custom-class="title">TERMS OF USE</h1>
    <p class="last-updated" data-custom-class="subtitle">Last updated September 18, 2024</p>

    <h2 data-custom-class="heading_1">AGREEMENT TO OUR LEGAL TERMS</h2><br>
    <p data-custom-class="body_text">We are Adibilis, Co. (doing business as Caddi) ("Company," "we," "us," "our").</p><br>
    <p data-custom-class="body_text">We operate the website <a class="link" href="https://trycaddi.com" target="_blank">https://trycaddi.com</a> (the "Site"), as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").</p><br>
    <p data-custom-class="body_text">You can contact us by email at <a data-custom-class="link" href="mailto:contact@trycaddi.com">contact@trycaddi.com</a>.</p> <br>
    <p data-custom-class="body_text">These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Adibilis, Co, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p><br>
    <p data-custom-class="body_text">Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.</p><br>
    <p data-custom-class="body_text">We recommend that you print a copy of these Legal Terms for your records.</p>

    <div class="table-of-contents">
        <h2 data-custom-class="heading_1">TABLE OF CONTENTS</h2>
        <ol data-custom-class="body_text">
            <li><a href="#services" data-custom-class="link">OUR SERVICES</a></li>
            <li><a href="#ip" data-custom-class="link">INTELLECTUAL PROPERTY RIGHTS</a></li>
            <li><a href="#userreps" data-custom-class="link">USER REPRESENTATIONS</a></li>
            <li><a href="#prohibited" data-custom-class="link">PROHIBITED ACTIVITIES</a></li>
            <li><a href="#ugc" data-custom-class="link">USER GENERATED CONTRIBUTIONS</a></li>
            <li><a href="#license" data-custom-class="link">CONTRIBUTION LICENSE</a></li>
            <li><a href="#management" data-custom-class="link">SERVICES MANAGEMENT</a></li>
            <li><a href="#terms" data-custom-class="link">TERM AND TERMINATION</a></li>
            <li><a href="#modifications" data-custom-class="link">MODIFICATIONS AND INTERRUPTIONS</a></li>
            <li><a href="#law" data-custom-class="link">GOVERNING LAW</a></li>
            <li><a href="#disputes" data-custom-class="link">DISPUTE RESOLUTION</a></li>
            <li><a href="#corrections" data-custom-class="link">CORRECTIONS</a></li>
            <li><a href="#disclaimer" data-custom-class="link">DISCLAIMER</a></li>
            <li><a href="#liability" data-custom-class="link">LIMITATIONS OF LIABILITY</a></li>
            <li><a href="#indemnification" data-custom-class="link">INDEMNIFICATION</a></li>
            <li><a href="#userdata" data-custom-class="link">USER DATA</a></li>
            <li><a href="#electronic" data-custom-class="link">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</a></li>
            <li><a href="#misc" data-custom-class="link">MISCELLANEOUS</a></li>
            <li><a href="#contact" data-custom-class="link">CONTACT US</a></li>
        </ol>
    </div>

    <div class="section" id="services">
        <h2 data-custom-class="heading_1">1. OUR SERVICES</h2>
        <p data-custom-class="body_text">The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
    </div>

    <div class="section" id="ip">
        <h2 data-custom-class="heading_1">2. INTELLECTUAL PROPERTY RIGHTS</h2>
        <h3 data-custom-class="heading_2">Our Intellectual Property</h3>
        <p data-custom-class="body_text">We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").</p>
        <p data-custom-class="body_text">Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world. The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or internal business purpose only.</p>

        <h3 data-custom-class="heading_2">Your Use of Our Services</h3>
        <p data-custom-class="body_text">You must not:</p>
        <ul>
            <li data-custom-class="body_text">Modify copies of any Content from the Services.</li>
            <li data-custom-class="body_text">Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
            <li data-custom-class="body_text">Delete or alter any copyright, trademark, or other proprietary rights notices from any Content.</li>
            <li data-custom-class="body_text">Access or use for any commercial purposes any part of the Services or any services or materials available through the Services.</li>
        </ul>
        <p data-custom-class="body_text">If you wish to make any use of the Content, or any portion of the Services other than that set out in this section, please address your request to: <a data-custom-class="link" href="mailto:contact@trycaddi.com">contact@trycaddi.com</a>.</p>
    </div>

<div class="section" id="userreps">
        <h2 data-custom-class="heading_1">3. USER REPRESENTATIONS</h2>
        <p data-custom-class="body_text">By using the Services, you represent and warrant that:</p>
        <ul>
            <li data-custom-class="body_text">You have the legal capacity and you agree to comply with these Legal Terms.</li>
            <li data-custom-class="body_text">You are not a minor in the jurisdiction in which you reside.</li>
            <li data-custom-class="body_text">You will not access the Services through automated or non-human means, whether through a bot, script, or otherwise.</li>
            <li data-custom-class="body_text">You will not use the Services for any illegal or unauthorized purpose.</li>
            <li data-custom-class="body_text">Your use of the Services will not violate any applicable law or regulation.</li>
        </ul>
    </div>

    <div class="section" id="prohibited">
        <h2 data-custom-class="heading_1">4. PROHIBITED ACTIVITIES</h2>
        <p data-custom-class="body_text">You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
        <p data-custom-class="body_text">As a user of the Services, you agree not to:</p>
        <ul>
            <li data-custom-class="body_text">Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without our consent.</li>
            <li data-custom-class="body_text">Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
            <li data-custom-class="body_text">Circumvent, disable, or otherwise interfere with security-related features of the Services.</li>
            <li data-custom-class="body_text">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
            <li data-custom-class="body_text">Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
        </ul>
    </div>

    <div class="section" id="ugc">
        <h2 data-custom-class="heading_1">5. USER GENERATED CONTRIBUTIONS</h2>
        <p data-custom-class="body_text">The Services may invite you to chat, contribute, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or to the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "User Contributions").</p>
        <p data-custom-class="body_text">Any User Contribution you transmit to us will be considered non-confidential and non-proprietary. You retain any and all of your rights to any User Contributions you submit, post, or display on or through the Services and you are responsible for protecting those rights. We are not responsible for any statements or representations in any User Contributions provided by you in any area of the Services. You bear all risks associated with the use of your User Contributions. You hereby grant us a license to use, reproduce, modify, publish, and distribute such User Contributions for any purpose.</p>
    </div>

    <div class="section" id="license">
        <h2 data-custom-class="heading_1">6. CONTRIBUTION LICENSE</h2>
        <p data-custom-class="body_text">By posting your User Contributions on the Services, you grant us, our affiliates, and our service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose such material to third parties.</p>
        <p data-custom-class="body_text">You represent and warrant that:</p>
        <ul>
            <li data-custom-class="body_text">You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us.</li>
            <li data-custom-class="body_text">All of your User Contributions do and will comply with these Legal Terms.</li>
        </ul>
        <p data-custom-class="body_text">You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not us, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</p>
    </div>

    <div class="section" id="management">
        <h2 data-custom-class="heading_1">7. SERVICES MANAGEMENT</h2>
        <p data-custom-class="body_text">We reserve the right, but not the obligation, to:</p>
        <ul>
            <li data-custom-class="body_text">Monitor the Services for violations of these Legal Terms.</li>
            <li data-custom-class="body_text">Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities.</li>
            <li data-custom-class="body_text">At our sole discretion and without limitation, notice, or liability, to restrict the availability of or disable any of your Contributions.</li>
            <li data-custom-class="body_text">Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.</li>
        </ul>
    </div>

    <div class="section" id="terms">
        <h2 data-custom-class="heading_1">8. TERM AND TERMINATION</h2>
        <p data-custom-class="body_text">These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION.</p>
        <p data-custom-class="body_text">If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a false or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</p>
    </div>

    <div class="section" id="modifications">
        <h2 data-custom-class="heading_1">9. MODIFICATIONS AND INTERRUPTIONS</h2>
        <p data-custom-class="body_text">We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information in our Services.</p>
        <p data-custom-class="body_text">We also reserve the right to modify or discontinue all or part of the Services without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.</p>
        <p data-custom-class="body_text">We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you.</p>
    </div>

    <div class="section" id="law">
        <h2 data-custom-class="heading_1">10. GOVERNING LAW</h2>
        <p data-custom-class="body_text">These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the State of Washington, without regard to its conflict of law principles.</p>
    </div>

<div class="section">
    <h2 data-custom-class="heading_1">11. DISPUTE RESOLUTION</h2>
    <h3 data-custom-class="heading_2">Informal Negotiations</h3>
    <p data-custom-class="body_text">To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a "Dispute" and collectively, the "Disputes") brought by either you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.</p>

    <h3 data-custom-class="heading_2">Binding Arbitration</h3>
    <p data-custom-class="body_text">Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as part of this clause. The number of arbitrators shall be proceedings shall be The governing law of these Legal Terms shall be substantive law of The seat, or legal place, or arbitration shall be The language of the</p>

    <h3 data-custom-class="heading_2">Restrictions</h3>
    <p data-custom-class="body_text">The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>

    <h3 data-custom-class="heading_2">Exceptions to Informal Negotiations and Arbitration</h3>
    <p data-custom-class="body_text">The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party, (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">12. CORRECTIONS</h2>
    <p data-custom-class="body_text">There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">13. DISCLAIMER</h2>
    <p data-custom-class="body_text">THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">14. LIMITATIONS OF LIABILITY</h2>
    <p data-custom-class="body_text">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US OR CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">15. INDEMNIFICATION</h2>
    <p data-custom-class="body_text">You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses, made by any third party due to or arising out of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and warranties set forth in these Legal Terms; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">16. USER DATA</h2>
    <p data-custom-class="body_text">We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
    <p data-custom-class="body_text">Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">18. MISCELLANEOUS</h2>
    <p data-custom-class="body_text">These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is found to be invalid, unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Legal Terms or your use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the Parties hereto to execute these Legal Terms.</p>
</div>

<div class="section">
    <h2 data-custom-class="heading_1">19. CONTACT US</h2>
    <p data-custom-class="body_text">To resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</p>
    <p data-custom-class="body_text">Email: contact@trycaddi.com<br>Mobile: +1 (929) 606-0056</p>
</div>

</body>
</html>`


    
export default TermsConditionsContent;