import React from 'react';

import download_image from '../pages/images/download-image.svg';
import record_image from '../pages/images/record-image.svg';
import caddi_automation_image from '../pages/images/caddi-automation.svg';

const StartSection = () => {
    return (
        <section className="section_start" id='section_start'>
            <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-large padding-top">
                        <div className="margin-bottom margin-small">
                            <div className="max-width-xlarge align-center text-align-center">
                                <div className="margin-bottom margin-small">
                                    <div className="glowing-wrapper glowing-wrapper-active tagline">
                                        <div className="glowing-wrapper-animations">
                                            <div className="glowing-wrapper-glow" />
                                            <div className="glowing-wrapper-mask-wrapper">
                                                <div className="glowing-wrapper-mask" />
                                            </div>
                                        </div>
                                        <div className="glowing-wrapper-borders-masker">
                                            <div className="glowing-wrapper-borders" />
                                        </div>
                                        <div className="glowing-wrapper-button">
                                            Quick Start
                                        </div>
                                    </div>
                                </div>
                                <h2 style={{color: '#26443C', fontSize: '2.5em'}}>
                                    Caddi is your all in one —
                                    <span className="text-color-primary">&nbsp;automation partner</span>
                                </h2>
                            </div>
                        </div>
                        <div className="w-layout-grid integration_grid">
                            <div className="integration_card">
                                <img src={download_image} loading="lazy" alt="" className="integration_card-thumbnail" />
                                <div className="integration_card-body">
                                <h3 className="integration_card-title">
                                    <a href="/download-app" className="download-link" target="_blank" rel="noopener noreferrer">
                                        Download our app
                                    </a>
                                </h3>
                                </div>
                            </div>
                            <div className="integration_card-divider" />
                            <div className="integration_card">
                                <img src={record_image} loading="lazy" alt="" className="integration_card-thumbnail" />
                                <div className="integration_card-body">
                                    <h3 className="integration_card-title">
                                        Record the process you want to automate
                                    </h3>
                                </div>
                            </div>
                            <div className="integration_card-divider" />
                            <div className="integration_card">
                                <img src={caddi_automation_image} loading="lazy" alt="" className="integration_card-thumbnail" />
                                <div className="integration_card-body">
                                    <h3 className="integration_card-title">
                                        Start using your custom automation
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StartSection;