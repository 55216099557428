import React from "react";
import logoImage from './images/logo-without-text.svg';
import logoInSquare from './images/logo-in-box.svg';

const DownloadApp = () => {
  const downloadMacOS = () => {
    console.log("Downloading Caddi app for macOS");
    // Example code for downloading app 

    // TODO: Change for URL of your macOS app installer (i.e. 'https://your-server.com/path/to/CaddiApp-macOS.dmg')
    const downloadUrl = '';
    // For testing
    // const downloadUrl = 'https://github.com/github/roadmap/archive/refs/heads/main.zip';


    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = downloadUrl;
    
    // Set the download attribute with the desired filename
    link.setAttribute('download', 'CaddiApp-macOS.dmg');
    
    // Append the link to the body
    document.body.appendChild(link);
    
    // Programmatically click the link to start the download
    link.click();
    
    // Remove the link from the body
    document.body.removeChild(link);

    // Optional: Track the download event


  };

  return (
    <div className="page-wrapper">
      <div className="navbar4_component w-nav" role="banner">
        <div className="navbar4_container">
          <a href="/" className="navbar4_logo-link w-nav-brand w--current">
            <img src={logoImage} loading="lazy" alt="" className="navbar4_logo" />
          </a>
          <div className="navbar4_wrapper">
            <div className="navbar-item">
              <a className="button is-navbar-button desktop w-button" href='/'>
                Return Home
              </a>
            </div>
          </div>
        </div>
      </div>
      <main className="main-wrapper">
        <section className="section_hero">
          <div className="padding-global">
            <div className="container-large">
              <div className="hero_component">
                <div className="hero_content" style={{ gap: '20px' }}>
                  <img
                    src={logoInSquare}
                    loading="lazy"
                    alt=""
                    style={{width: '200px', height: 'auto'}}
                  />
                  <h2 style={{ marginBottom: '10px' }}>
                    Download the
                    <span className="text-color-primary"> Caddi app</span>
                  </h2>
                  <p style={{ marginBottom: '20px' }} className="hero_para">
                    Get the best Caddi experience by downloading the Caddi app for Mac.
                  </p>
                  <div className="button-group hero_button-group" style={{ marginTop: '10px' }}>
                    <button 
                      className="button is-navbar-button desktop w-button"
                      style={{ opacity: 0.5, cursor: 'not-allowed' }}
                    >
                      Windows (coming soon)
                    </button>
                    <button onClick={downloadMacOS} className="button is-navbar-button desktop w-button">
                      Download for macOS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DownloadApp;