import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import SignInButton from "./SignInButton";
import LogoutButton from "./LogoutButton";
import logoImage from '../pages/images/logo-without-text.svg';

const Navbar = () => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);
    const { isAuthenticated } = useAuth0();

    const logOutButtonStyles = {
        color: "white",
        backgroundColor: "black",
    };


    const toggleMenu = () => {
        setIsMenuExpanded(current => !current);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const navbar = document.querySelector('.navbar4_component');
            if (isMenuExpanded && navbar && !navbar.contains(event.target)) {
                setIsMenuExpanded(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMenuExpanded]);

    useEffect(() => {
        const handleMenuAnimation = () => {
            const menuIcon = document.querySelector('.menu-icon4');
            const topLine = document.querySelector('.menu-icon4_line-top');
            const middleLine = document.querySelector('.menu-icon4_line-middle');
            const bottomLine = document.querySelector('.menu-icon4_line-bottom');

            if (isMenuExpanded) {
                menuIcon.classList.add('open');
                topLine.style.transform = 'rotate(45deg) translate(6px, 6px)';
                middleLine.style.opacity = '0';
                bottomLine.style.transform = 'rotate(-45deg) translate(6px, -6px)';
            } else {
                menuIcon.classList.remove('open');
                topLine.style.transform = 'rotate(0) translate(0, 0)';
                middleLine.style.opacity = '1';
                bottomLine.style.transform = 'rotate(0) translate(0, 0)';
            }
        };

        handleMenuAnimation();
    }, [isMenuExpanded]);

    return (
        <div
        data-animation="default"
        className="navbar4_component w-nav"
        data-easing2="ease"
        fs-scrolldisable-element="smart-nav"
        data-easing="ease"
        data-collapse="all"
        data-w-id="e13ab862-2b2a-b56e-232c-9e2d2f0da996"
        role="banner"
        data-duration={400}
        data-doc-height={1}
    >
        <div className="navbar4_container">
            <a
                href="/"
                aria-current="page"
                className="navbar4_logo-link w-nav-brand w--current"
            >
                <img
                    src={logoImage}
                    loading="lazy"
                    alt=""
                    className="navbar4_logo"
                />
            </a>
            <div className="navbar4_wrapper">
                {!isAuthenticated && (

                    <a className="button is-navbar-button desktop w-button" href="/schedule-demo">
                        Request demo
                    </a>
                )}

                {/* Dynamic buttons to sign in, go to dashboard */}
                {!isAuthenticated && (
                    <div id="navbarMenu" className="navbar-menu">
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <SignInButton className="button is-navbar-button desktop w-button" />
                            </div>
                        </div>
                    </div>
                )}

                {isAuthenticated && (
                    <div id="navbarMenu" className="navbar-menu">
                        <div className="navbar-end">
                            <div className="navbar-item">
                                <a className="button is-navbar-button desktop w-button" href="/dashboard">
                                    Dashboard
                                </a>
                            </div>
                            <div className="navbar-item">
                                <LogoutButton className="button is-navbar-button desktop w-button" style={logOutButtonStyles} />
                            </div>
                        </div>
                    </div>
                )}

                <div className={`navbar4_menu-wrapper-inner ${isMenuExpanded ? 'expand' : ''}`}>
                    <div
                        className="dropdown-menu"
                        style={{
                            position: 'absolute',
                            // width: '100vw',
                            // height: '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            backgroundColor: '#FFFFFF',
                            zIndex: 1000,
                            top: '100%',
                            overflow: 'auto',
                            // left: 0,
                            opacity: isMenuExpanded ? 1 : 0,
                            visibility: isMenuExpanded ? 'visible' : 'hidden',
                            transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                            padding: '1rem',
                        }}
                    >
                        {!isAuthenticated ? (
                            <div className="button-container" style={{ textAlign: 'center' }}>
                                <SignInButton className="button is-navbar-button desktop w-button" />
                                <br></br>
                                <br></br>
                                <a href="/schedule-demo" className="button is-navbar-button desktop w-button">
                                    Request Demo
                                </a>
                            </div>
                        ) : (
                            <div>
                                <a href="/dashboard" className="button is-navbar-button desktop w-button">
                                    Dashboard
                                </a>
                                <br></br>
                                <br></br>
                                <LogoutButton className="button is-navbar-button desktop w-button" style={logOutButtonStyles} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="navbar4_menu-button w-nav-button" onClick={toggleMenu}>
                    <div className="menu-icon4">
                        <div className="menu-icon4_wrapper">
                            <div className="menu-icon4_line-top" />
                            <div className="menu-icon4_line-middle">
                                <div className="menu-icon_line-middle-top" />
                                <div className="menu-icon_line-middle-base" />
                            </div>
                            <div className="menu-icon4_line-bottom" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    );
};

export default Navbar;
