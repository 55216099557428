import React from "react";
import logoImage from './images/logo-without-text.svg';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';

const PrivacyPolicyPage = () => {
  return (
    <div className="page-wrapper">
      <nav className="navbar4_component w-nav" role="navigation">
        <div className="navbar4_container">
          <a href="/" className="navbar4_logo-link w-nav-brand">
            <img src={logoImage} loading="lazy" alt="Logo" className="navbar4_logo" />
          </a>
          <div className="navbar4_wrapper">
            <div className="navbar-item">
              <a className="button is-navbar-button desktop w-button" href='/'>
                Return Home
              </a>
            </div>
          </div>
        </div>
      </nav>
      <main className="main-wrapper">
        <section className="privacy-policy-section">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="bg-white shadow-md rounded-lg p-6">
              <div dangerouslySetInnerHTML={{ __html: PrivacyPolicyContent }} />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicyPage;