import React from 'react';
import group63 from '../pages/images/Group-63.svg';
import caddiLogo from '../pages/images/Caddi-Logo.svg';
import group98 from '../pages/images/Group-98.svg';

const FooterSection = () => {
    return (
        <footer className="section_footer">
            <div className="padding-global">
                <div className="container-large">
                    <div className="cta_component">
                        <div className="cta_content">
                            <div className="margin-bottom margin-small">
                                <div
                                    data-w-id="29526d87-490b-50ff-fbbe-3fda0838a711"
                                    style={{ opacity: 100 }}
                                    className="glowing-wrapper glowing-wrapper-active tagline"
                                >
                                    <div className="glowing-wrapper-animations">
                                        <div className="glowing-wrapper-glow" />
                                        <div className="glowing-wrapper-mask-wrapper">
                                            <div className="glowing-wrapper-mask" />
                                        </div>
                                    </div>
                                    <div className="glowing-wrapper-borders-masker">
                                        <div className="glowing-wrapper-borders" />
                                    </div>
                                    <div className="glowing-wrapper-button setup-demo-text">Setup a Demo</div>
                                </div>
                            </div>
                            <div
                                data-w-id="8e9d675a-bed5-a489-4400-1e1b28b4f91c"
                                style={{ opacity: 100 }}
                                className="cta_title-wrapper"
                            >
                                <h2
                                    data-w-id="465688af-52a3-f61f-5a93-6c18a40f5d5b"
                                    style={{ opacity: 100 }}
                                    className="cta_title ready-integrate-text"
                                >
                                    Ready to integrate
                                    <span className="text-color-primary">&nbsp;Caddi?</span>
                                </h2>
                                <a
                                    data-w-id="681358bb-b1f6-15bb-2f5c-8197d64bb838"
                                    style={{ opacity: 100 }}
                                    href="/schedule-demo"
                                    className="cta_link w-inline-block"
                                >
                                    <div className="cta_link-icon w-embed">
                                        <svg
                                            width="100%"
                                            height="100%"
                                            viewBox="0 0 30 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M0.12281 11.8623H25.7253L22.8455 13.8742L9.63006 0.658708H15.9814L29.4335 14.1898L15.9419 27.7208H9.63006L22.8455 14.5054L25.7253 16.5173H0.12281V11.8623Z"
                                                fill="currentcolor"
                                                style={{
                                                    fill: "color(display-p3 0.2272 0.176 0.0696)",
                                                    fillOpacity: 1
                                                }}
                                            />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <img
                            src={group63}
                            loading="lazy"
                            alt=""
                            className="cta_content-shape"
                        />
                    </div>
                    <div className="footer_component">
                        <div
                            id="w-node-cdce71c5-2429-936d-b2d3-e8f9498df05c-acad4190"
                            className="footer_first-widget"
                        >
                            <div
                                data-w-id="0658bd67-3455-1283-5e54-57fae1654e8b"
                                style={{ opacity: 100 }}
                                className="footer_widget-divider"
                            />
                            <div
                                data-w-id="e9433552-9a37-4f2c-6c77-864210b14f96"
                                style={{ opacity: 100 }}
                                className="footer_first-widget-inner"
                            >
                                <div className="footer_first-widget-inner-top">
                                    <a
                                        href="/"
                                        aria-current="page"
                                        className="footer_link w-inline-block w--current"
                                    >
                                        <img
                                            src={group98}
                                            loading="lazy"
                                            alt=""
                                            className="footer_logo"
                                        />
                                    </a>
                                    <div>Client Intake Solutions</div>
                                    <a
                                        data-w-id="ef09367e-d98f-2e97-f54a-b77e1f533bff"
                                        href="#"
                                        className="social-link w-inline-block"
                                    >
                                        <div className="social-link-inner">
                                            <div className="social-icon-wrap">
                                                <div className="social-icon w-embed">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        aria-hidden="true"
                                                        role="img"
                                                        className="iconify iconify--bx"
                                                        width="100%"
                                                        height="100%"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            cx="4.983"
                                                            cy="5.009"
                                                            r="2.188"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div>LinkedIn</div>
                                        </div>
                                    </a>
                                </div>
                                <a
                                    href="/"
                                    aria-current="page"
                                    className="footer_botton-link w-inline-block w--current"
                                >
                                    <img
                                        src={caddiLogo}
                                        loading="lazy"
                                        alt=""
                                        className="footer_botton-logo"
                                    />
                                </a>
                            </div>
                            <div
                                data-w-id="b866dce2-9a15-2ab2-0bb2-da92f10a44bb"
                                style={{ opacity: 100 }}
                                className="footer_widget-divider"
                            />
                        </div>
                        <div
                            id="w-node-_21477a02-ad59-152c-b614-92b952da8b22-acad4190"
                            className="footer_menu-widget"
                        >
                            <div className="footer_menu-widget-inner">
                                <div
                                    data-w-id="2d8b5427-32cd-bff8-de09-235e5cd0032b"
                                    style={{ opacity: 100 }}
                                    className="footer_menu-wrap"
                                >
                                    <div className="footer_menu-title">Product</div>
                                    <ul role="list" className="footer_menu-list w-list-unstyled">
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Benefits
                                            </a>
                                        </li>
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Custom
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    data-w-id="5e357b4c-5ad5-7f60-5518-ffa3814902d1"
                                    style={{ opacity: 100 }}
                                    className="footer_menu-wrap"
                                >
                                    <div className="footer_menu-title">Company</div>
                                    <ul role="list" className="footer_menu-list w-list-unstyled">
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Investors
                                            </a>
                                        </li>
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Careers
                                            </a>
                                        </li>
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                About Us
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    data-w-id="c4537b5b-f1f0-4cca-47ba-86d436b33369"
                                    style={{ opacity: 100 }}
                                    className="footer_menu-wrap"
                                >
                                    <div className="footer_menu-title">Industries</div>
                                    <ul role="list" className="footer_menu-list w-list-unstyled">
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Legal
                                            </a>
                                        </li>
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Financial Services
                                            </a>
                                        </li>
                                        {/* <li className="footer_menu-list-item">
            <a href="#" className="footer_menu-link">
                Personal Injury
            </a>
            </li>
            <li className="footer_menu-list-item">
            <a href="#" className="footer_menu-link">
                Employment Law
            </a>
            </li>
            <li className="footer_menu-list-item">
            <a href="#" className="footer_menu-link">
                Business Law
            </a>
            </li>
            <li className="footer_menu-list-item">
            <a href="#" className="footer_menu-link">
                Immigration
            </a>
            </li>
            <li className="footer_menu-list-item">
            <a href="#" className="footer_menu-link">
                Real Estate Law
            </a>
            </li> */}
                                    </ul>
                                </div>
                                <div
                                    data-w-id="c0e307b4-2271-d334-68c3-312b61532f41"
                                    style={{ opacity: 100 }}
                                    className="footer_menu-wrap"
                                >
                                    <div className="footer_menu-title">Resources</div>
                                    <ul role="list" className="footer_menu-list w-list-unstyled">
                                        {/* <li className="footer_menu-list-item">
            <a href="#" className="footer_menu-link">
                Data Privacy
            </a>
            </li> */}
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                FAQ
                                            </a>
                                        </li>
                                        <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Blog
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer_menu-widget-inner bottom">
                                <div
                                    data-w-id="af731701-cf50-3af2-09a1-006fb140a461"
                                    style={{ opacity: 100 }}
                                    className="footer_menu-wrap"
                                >
                                    <ul role="list" className="footer_menu-list w-list-unstyled">
                                        <li className="footer_menu-list-item">
                                            <a href="/terms-conditions" className="footer_menu-link">
                                                Website &amp; Platform Terms of Use
                                            </a>
                                        </li>
                                        <li className="footer_menu-list-item">
                                            <a href="/privacy" className="footer_menu-link">
                                                Privacy Policy
                                            </a>
                                        </li>
                                        {/* <li className="footer_menu-list-item">
                                            <a href="#" className="footer_menu-link">
                                                Cookies Policy
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div
                                    data-w-id="af731701-cf50-3af2-09a1-006fb140a488"
                                    style={{ opacity: 100 }}
                                    className="copyright-wrap"
                                >
                                    <div className="copyright">©2024 Caddi Technologies LLC</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterSection;