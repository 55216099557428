import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "./pages/LoadingScreen";
import Dashboard from "./pages/Dashboard";
import Credentials from "./pages/Credentials";
import LandingPage from "./pages/LandingPage";
import ScheduleDemo from "./pages/ScheduleDemo";
import DownloadApp from "./pages/DownloadApp";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsConditionsPage from "./pages/TermsConditionsPage";
import TaskManager from "./pages/TaskManager";
import LoopScreen from "./pages/LoopScreen";
import DetailedLoopView from "./pages/DetailedLoopView";
import Callback from "./pages/Callback";


function ProtectedRoute({ component: Component, ...rest }) {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingScreen />;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
}

function App() {
  console.log("Loading App.js");
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      console.log("isAuthenticated:", isAuthenticated);
      console.log("user:", user);

      if (isAuthenticated && user) {
        getDataFromAuth0(user.sub);
      } else {
        localStorage.removeItem("isSignedIn");
      }
      setIsAppReady(true);
    }
  }, [isLoading, isAuthenticated, user]);

  const getDataFromAuth0 = async (userId) => {
    try {
      const token = await getAccessTokenSilently();
      // Use the token to fetch user data from your backend or Auth0 Management API
      // Store relevant data in localStorage or state as needed
      localStorage.setItem("user_id", userId);
      localStorage.setItem("isSignedIn", "true");
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const clearStates = () => {
    localStorage.clear();
  };

  const onLogout = () => {
    clearStates();
  };

  if (isLoading || !isAppReady) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/download-app" element={<DownloadApp />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-conditions" element={<TermsConditionsPage />} />
      <Route path="/schedule-demo" element={<ScheduleDemo />} />
      <Route path="/loading" element={<LoadingScreen />} />
      <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
      <Route path="/credentials" element={<ProtectedRoute component={Credentials} />} />
      <Route path="/task-manager" element={<ProtectedRoute component={TaskManager} />} />
      <Route path="/loops" element={<ProtectedRoute component={LoopScreen} />} />
      <Route path="/loops/:loopId" element={<ProtectedRoute component={DetailedLoopView} />} />
      <Route path="/callback" element={<Callback />} />
    </Routes>
  );
}

export default App;