import React from 'react';
import smart_task_image from '../pages/images/caddi-security.svg';

const CaddiSecuritySection = () => {
    return (
        <section className="section_caddi_security">
            <div className="padding-global">
                <div className="container-large">
                    <div className="padding-section-large ">
                        <div className="margin-bottom margin-xlarge">
                            <div className="max-width-xlarge ">   
                                <div className="margin-bottom margin-small">
                                <div
                                    data-w-id="74ad06de-982a-32fa-f2b9-49b478412f73"
                                    style={{ opacity: 100 }}
                                    className="glowing-wrapper glowing-wrapper-active tagline"
                                >
                                    <div className="glowing-wrapper-animations">
                                    <div className="glowing-wrapper-glow" />
                                    <div className="glowing-wrapper-mask-wrapper">
                                        <div className="glowing-wrapper-mask" />
                                    </div>
                                    </div>
                                    <div className="glowing-wrapper-borders-masker">
                                    <div className="glowing-wrapper-borders" />
                                    </div>
                                        <div className="glowing-wrapper-button">Caddi Security</div>
                                    </div>
                                </div>
                                <h2 className="section-title-left">
                                    Secure, Reliable <span className="text-color-primary">Automation</span>
                                </h2>
                            </div>
                        </div>
                        <div className="caddi-security_content">
                            <div className="caddi-security_card">
                                <div className="caddi-security_card-body">
                                    <p>
                                        Your data security is our top priority. Caddi uses industry-leading encryption, secure data storage, and rigorous privacy standards to ensure your information stays safe. We never store or share your data with third parties. 
                                    </p>
                                </div>
                                <div className="caddi-security_card-image">
                                    <img
                                        src={smart_task_image}
                                        loading="lazy"
                                        alt="Smart Task Detection"
                                        className="caddi-security_card-thumbnail"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CaddiSecuritySection;